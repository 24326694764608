@import url("https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.theme.default.min.css");

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
/* .navbar-brand img{
  height: 100px;
} */
 .footer-item img{
  height: 100px;
 }
.hero {
  width: 100%;
  min-height: 80vh;
  position: relative;
  padding: 80px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--default-color);
}

.hero img {
  position: absolute;
  inset: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.hero:before {
  content: "";
  background: color-mix(in srgb, var(--background-color), transparent 60%);
  position: absolute;
  inset: 0;
  z-index: 2;
}

.hero .container {
  position: relative;
  z-index: 3;
}

.hero h2 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
}

.hero p {
  color: color-mix(in srgb, var(--default-color), transparent 20%);
  margin: 10px 0 0 0;
  font-size: 24px;
}

.hero .btn-get-started {
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 35px 10px 35px;
  border-radius: 50px;
  transition: 0.4s;
  margin-top: 30px;
  border: 2px solid var(--default-color);
  color: var(--default-color);
}

.hero .btn-get-started:hover {
  background: var(--accent-color);
  border: 2px solid var(--accent-color);
}

@media (max-width: 768px) {
  .hero h2 {
    font-size: 32px;
  }

  .hero p {
    font-size: 18px;
  }
}

.donate-home{
  font-size: 18px;
}

.about-img img{
  height: 230px;
  object-fit: cover;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: #fff; /* Ensure the navbar has a background color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sticky + .content {
  padding-top: 70px; /* Adjust this value based on your navbar height */
}


.service-containers{
  width: 100%;
  padding: 0% 5%;
}

.service-img-1{
  width: 100%;
  height: 600px;
}

.service-content-1{
  background-color: rgb(240, 240, 240);
  width: 50%;
  padding:2%;
  text-align: justify;
  padding-top: 45px;
  line-height: 30px;

}
.homepage-donate{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.homeservice-img-1{
  width: 100%;
  height: 650px;
}
.homeservice-img-1 img{
  object-fit: contain;
}
.homeservice-content-1{
  background-color: rgb(240, 240, 240);
  height: 650px;
  padding:2%;
  text-align: justify;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  text-align: justify;
  background-color: #faf4d6;
}


.home-heading{
  width: 800px;
}

.Instagram{
  width: 100%;
}

@media (max-width:786px){

  .service-img-1{
    width: 100%;
    height: 492px;
  }

  .service-content-1{
    background-color: rgb(240, 240, 240);
    width: 100%;
    padding:2%;
    text-align: justify;
  
  }
  
  .home-heading{
    width: 100%;
  }

  .sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: #fff; /* Ensure the navbar has a background color */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .donate-home{
    font-size: 14px;
  }

  .hero{
    width: 100%;
  }
  .Instagram{
    width: 100%;
  }

  .homepage-donate{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .homeservice-img-1{
    width: 100%;
    height: 400px;
  }
  .homeservice-img-1 img{
    object-fit: contain;
  }
  .homeservice-content-1{
    background-color: rgb(233 228 228);
    height: 250px;
    padding:2%;
    text-align: justify;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    
  }

}

.donate-page {
  height: 85vh;
  background-image: url(../public/assets/img/donatehome/donate3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}


.donation_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  position: absolute;
  top: 100%;
  left: 50%;
  font-family: "Raleway", sans-serif;
  padding: 25px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  background: #f5f5f5;
  transform: translateX(-50%) translateY(-50%);
  transition: all 0.3s;
}

.donation_wrapper:hover {
  box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2);
  background: #f0f0f0;
}

.donation_wrapper .title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  font-size: 25px;
  user-select: none;
}

.donation_wrapper .single_amount_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 30px 20px;
}

.donation_wrapper .single_amount {
  padding: 5px 10px;
  margin: 0 5px;
  border-radius: 25px;
  color: #fff;
  background: #0070ba;
  cursor: pointer;
}

.donation_wrapper .single_amount:hover {
  background: #003087;
}

.donation_wrapper .amount_wrapper {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: row;
}

.donation_wrapper .amount_wrapper input,
.amount_wrapper .suffix {
  padding: 8px;
  border: 0;
  font-weight: bold;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  background: #fff;
}

.donation_wrapper .amount_wrapper input {
  border-radius: 8px 0 0 8px;
  border-right: 0;
  outline: none;
}

.donation_wrapper .amount_wrapper .suffix {
  border-radius: 0 8px 8px 0;
  user-select: none;
}

.donation_wrapper a {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  padding: 15px 25px;
  border: 0;
  color: #fff;
  font-size: 16px;
  text-decoration: none;
  border-radius: 25px;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0) inset,
    0 0 0 0 rgba(0, 0, 0, 0) inset;
  background: #0070ba;
  transition: all 0.3s;
}

.donation_wrapper a:hover {
  background: #003087;
}

.donation_wrapper a:active {
  box-shadow: 0 -2px 2px 0 rgba(255, 255, 255, 0.2) inset,
    0 2px 2px 0 rgba(0, 0, 0, 0.5) inset;
}

.service-main{
  width: 100%;
}

.slick-slide img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}


.homecarousel-shadow-effect img{
  height: 80vh;
  object-fit: cover;
}


.homecarousel-testimonials {
  position: relative;
 
}

.homecarousel-slider-caption {
  position: absolute;
  width: 100%;
  text-align:left;
  top: 250px;
  left: 30px;
  padding: 20px;
  z-index: 100;

}

.homecarousel-slider-caption h2 {
  color: #16243d;;
  text-transform: uppercase;
  font-size: 60px;
}

.homecarousel-top-caption h2 {
  margin-bottom: -30px;
  font-family: 'Lora', serif;
}

.homecarousel-bottom-caption h2 {
  margin-top: -30px;
  font-family: 'Lora', serif;
}

.homecarousel-middle-caption {
  /* background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 4px #333; */
  display: inline-block;
  padding: 30px 50px;
  text-align: center;
  z-index: 99;
  width: auto;
  margin: 0 auto;
}

.homecarousel-middle-caption h2 {
  font-family: 'Tangerine', cursive;
  font-weight: 400;
  font-size: 90px;
  line-height: 90px;
  color: #16243d;;
  text-transform: capitalize;
}

.homecarousel-slider-caption2 {
  top:250px;
}

.homecarousel-shadow-effect {
  position: relative;
}

.owl-carousel .owl-nav [class*='owl-'] {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.owl-carousel .owl-nav [class*='owl-'].disabled:hover {
  background-color: #d6d6d6;
}

.owl-carousel {
  position: relative;
}

.owl-carousel .owl-next,
.owl-carousel .owl-prev {
  width: 22px;
  height: 40px;
  margin-top: -20px;
  position: absolute;
  top: 45%;
  color: #fff;
}

.owl-carousel .owl-prev {
  left: 10px;
}

.owl-carousel .owl-next {
  right: 20px;
}
@media (max-width:786px){
  .homecarousel-slider-caption {
    position: absolute;
    width: 100%;
    text-align:left;
    top: 200px;
    left: 30px;
    padding: 20px;
    z-index: 100;
  
  }
  
  .homecarousel-slider-caption h2 {
    color: #16243d;;
    text-transform: uppercase;
    font-size: 30px;
  }
  .homecarousel-middle-caption h2 {
    font-family: 'Tangerine', cursive;
    font-weight: 600;
    font-size: 20px;
    line-height: 90px;
    color: #16243d;;
    text-transform: capitalize;
  }
  
  .homecarousel-slider-caption2 {
    top:200px;
  }
}
#home-galleys img{
height: 300px;
object-fit: fill;
}